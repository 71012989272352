/* Provide sufficient contrast against white background */

@media(max-width:0px) {
    h1 {
        /*font-size: 1.5rem;*/
        color: #000000;
        font-family: Poppins, sans-serif;
/*        font-weight: bold;
        font-stretch: condensed; */

    }
    h1white {
        /*font-size: 400px;*/
        color: #ffffff;
        font-family: Poppins, sans-serif;
        font-weight: 400;
        /*font-weight:*/
        /*font-stretch: condensed;*/
    }

    h2 {
        font-size: 1.2rem;
        color: #333333;
        font-family: Poppins, sans-serif;
        font-weight: lighter;
        font-stretch: condensed;
    }

    h2white {
        font-size: 1.2rem;
        color: #ffffff;
        font-family: Poppins, sans-serif;
        font-weight: lighter;
        font-stretch: condensed;
    }

    h3 {
        font-family: Roboto, sans-serif;
        font-size: 1.2rem;
        font-weight: lighter;
        color: #000000;
        font-stretch: condensed;
    }

    h3white {
        font-family: Roboto, sans-serif;
        font-size: 1.75rem;
        font-weight: lighter;
        color: #ffffff;
        font-stretch: condensed;
    }

    h4 {
        font-family: Roboto, sans-serif;
        font-size: .875rem;
        font-weight: bold;
        color: #4E106F;
        font-stretch: condensed;
    }

    h5 {
        font-family: Roboto, sans-serif;
        font-size: .875rem;
        font-weight: bold;
        color: #333333;
        font-stretch: condensed;
    }

    h5normal {
        font-family: Roboto, sans-serif;
        font-size: 1.3rem;
        font-weight: normal;
        color: #1a1a1a;
        font-stretch: condensed;
    }

    h6 {
        font-family: Roboto, sans-serif;
        font-size: 1.3rem;
        font-weight: bold;
        color: #4E106F;
        padding-bottom: 10px;
        padding-top: 25px;
        font-stretch: condensed;
    }

    h7 {
        font-family: Roboto, sans-serif;
        font-size: 1.3rem;
        font-weight: normal;
        color: #ef2a79;
        padding-bottom: 10px;
        padding-top: 25px;
        font-stretch: condensed;
    }
   


}

@media(min-width:600px) {
    h1 {
        font-size: 2.25rem;
        color: #000000;
        font-family: Roboto, sans-serif;
        font-weight: bold;
        font-stretch: condensed;
    }

    h1white {
        font-size: 2.25rem;
        color: #ffffff;
        font-family: Poppins, sans-serif;
        font-weight: 400;
        /*font-weight:*/
        /*font-stretch: condensed;*/
    }


    h2 {
        font-size: 1.75rem;
        color: #333333;
        font-family: Roboto, sans-serif;
        font-weight: lighter;
        font-stretch: condensed;
    }

    h2white {
        font-size: 1.75rem;
        color: #ffffff;
        font-family: Roboto, sans-serif;
        font-weight: lighter;
        font-stretch: condensed;
    }

    h3 {
        font-family: Roboto, sans-serif;
        font-size: 1.75rem;
        font-weight: lighter;
        color: #000000;
        font-stretch: condensed;
    }
    h3white {
        font-family: Roboto, sans-serif;
        font-size: 1.75rem;
        font-weight: lighter;
        color: #ffffff;
        font-stretch: condensed;
    }

    h4 {
        font-family: Roboto, sans-serif;
        font-size: calc(min(1.3rem,21));
        font-weight: bold;
        color: #4E106F;
        font-stretch: condensed;
    }

    h5 {
        font-family: Roboto, sans-serif;
        font-size: 1.3rem;
        font-weight: bold;
        color: #1a1a1a;
        font-stretch: condensed;
    }

    h5normal {
        font-family: Roboto, sans-serif;
        font-size: 1.3rem;
        font-weight: normal;
        color: #1a1a1a;
        font-stretch: condensed;
    }

    h6 {
        font-family: Roboto, sans-serif;
        font-size: 1.3rem;
        font-weight: bold;
        color: #4E106F;
        padding-bottom: 10px;
        padding-top: 25px;
        font-stretch: condensed;
    }
    h7 {
        font-family: Roboto, sans-serif;
        font-size: 1.3rem;
        font-weight: normal;
        color: #ef2a79;
        padding-bottom: 10px;
        padding-top: 25px;
        font-stretch: condensed;
    }
}

body {
    font-family: Roboto, sans-serif;
    color: #000000;
    min-width: 100%;
    font-stretch: condensed;
}

.loader-center-container {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -25px;
    margin-top: -25px;
}

.custom-loader {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: radial-gradient(farthest-side,#4e106f 94%,#0000) top/5px 5px no-repeat, conic-gradient(#0000 5%,#efefef 20%,#ff9971 50%,#4e106f 90%);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 5px),#000 0);
    animation: s3 1s infinite linear;
}

@keyframes s3 {
    100% {
        transform: rotate(1turn)
    }
}






a {
    color: #EF2A79;
    font-family: Roboto, sans-serif;
    font-stretch: condensed;
}

    a:hover {
        color: #EF2A79
    }

ul {
    font-family: Roboto, sans-serif;
    font-size: 1.2rem;
    font-weight: normal;
    color: #333333;
    font-stretch: condensed;
}


li {
    font-family: Roboto, sans-serif;
    font-size: 1.2rem;
    font-weight: normal;
    color: #333333;
    font-stretch: condensed;
}

p {
    font-family: Roboto, sans-serif;
    font-size: 1.2rem;
    font-weight: normal;
    color: #333333;
    font-stretch: condensed;
}

code {
    color: #4E106F;
}

/*button:focus {
    outline: 0px auto white
    }*/
/*button:hover {
    border-color: #4E106F;
    background-color: #4e106f;
}*/
button:active {
    color: #4e106f;
    border-color: #4E106F !important;
    cursor: pointer;
    box-shadow: none;
}

button:focus {
    /*background-color: #4e106f;*/
    border-color: #333333 !important;
    outline: none;
    box-shadow: none;
}

input:focus {
    outline: 0px auto -webkit-focus-ring-color
}


.btn-primary-tab {
    color: #fff;
    background-color: #4E106F;
    border-color: #4E106F;
    font-size: .8rem;
}

.btn-primary-tab:active {
    background-color: #4e106f;
    border-color: #333333;
}

.btn-primary-tab:focus {
    background-color: #4e106f;
    border-color: #333333;
}


.btn-primary-tab:hover {
    color: #fff;
    background-color: #fff;
    border-color: #333333;
    font-size: .8rem;
    box-shadow: rgba(0,0,0,.6) 2px 2px 2px 2px;
}

.btn-primary {
    color: #fff;
    background-color: #4E106F;
    border-color: #4E106F !important;
    font-size: .8rem;
    outline: none;
    box-shadow: none;
}

    .btn-primary:active {
        background-color: #4e106f !important;
        border-color: #333333 !important;
        outline: none;
        box-shadow: none;
    }

    .btn-primary:focus {
        background-color: #4e106f;
        border-color: #333333 !important;
        outline: none;
        box-shadow: none;
    }


    .btn-primary:hover {
        color: #fff;
        background-color: #4E106F;
        border-color: #333333;
        font-size: .8rem;
        box-shadow: rgba(0,0,0,.6) 2px 2px 2px 2px;
    }


.btn-secondarynpd {
    color: #fff;
    background-color: #4E106F;
    border-color: #4E106F;
    font-size: .8rem;
}

    .btn-secondarynpd:hover {
        color: #fff;
        background-color: #4E106F;
        border-color: #565a5c;
        font-size: .8rem;
        box-shadow: rgba(0,0,0,.6) 2px 2px 2px 2px;
    }

.btn-secondary {
    color: #fff;
    background-color: #4E106F;
    border-color: #4E106F;
    font-size: .8rem;
}

    .btn-secondary:hover {
        color: #fff;
        background-color: #4E106F;
        border-color: #565a5c;
        font-size: .8rem;
        box-shadow: rgba(0,0,0,.6) 2px 2px 2px 2px;
    }

.btn-cancel {
    color: #fff;
    background-color: #822433;
    border-color: #822433;
    font-size: .8rem;
}

.btn-cancel:hover {
    color: #fff;
    color: #fff;
    background-color: #822433;
    border-color: #565a5c;
    font-size: .8rem;
    box-shadow: rgba(0,0,0,.6) 2px 2px 2px 2px;
}

.testclass {
    color: #fff;
    background-color: #4e106f;        
}

.banner {
    padding-right: 0;
    max-width: calc(100vw - 120px);
    min-width: 1200px;
    width: 100%;
    height: 150px;
    background-image: url(../images/background.jpg);
    /*background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(../images/background.jpg);*/
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 25%;
    padding: 30px;
    border-top: 4px solid #000000;
    border-bottom: 2px solid #CCC;
    /*opacity: '0.2';*/
    text-align: center;
    position: absolute;
    left: 120px
}

.banner_output {
    padding-right: 0;
    max-width: calc(100vw - 120px);
    min-width: 1200px;
    width: 100%;
    height: 75px;
    background-image: url(../images/background.jpg);
    /*background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(../images/background.jpg);*/
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    padding: 15px;
    border-top: 4px solid #000000;
    border-bottom: 2px solid #CCC;
    /*opacity: '0.2';*/
    text-align: center;
    position: absolute;
    left: 120px
}

.bannerfull {
    padding-right: 0;
   
    max-width: calc(100vw - 10px);
    /*min-width: 1200px;*/
    height: 225px;
    width: 100%;
    /*height: 150px;*/
    background-image: url(../images/background.jpg);
    /*background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(../images/background.jpg);*/
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    padding: 2px;
    border-top: 4px solid #000000;
    border-bottom: 2px solid #CCC;
    /*opacity: '0.2';*/
    text-align: center;
    /*position: absolute;
    left: 120px*/
}

.contentHolder {
    background-color: #FFF;
    border-radius: 10px;
    border-color: #565A5C;
    border-width: 0px;
    border-style: groove;
    padding: 40px;
   
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 15px;
    margin-bottom: 15px;
    /*z-index: 100000;*/
    box-shadow: 10px 10px 5px rgba(0,0,0,.1);
}

.contentHolderBlack {
    background-color: #000000;
    /* border-radius: 10px;
    border-color: #565A5C;
    border-width: 0px;
    border-style: groove;
    margin: auto;*/
    display: flex;
    justify-content: center;
    /*padding: 40px;
    padding-left: 20px;
    padding-right: 20px;*/
    /* margin-left: 40px;
    margin-right: 40px;
    margin-top: 15px;
    margin-bottom: 15px;*/
    /*z-index: 100000;*/
    /* box-shadow: 10px 10px 5px rgba(0,0,0,.1);*/
}

.contentHolderGradient {
    background: linear-gradient(#ffffff, #000000);
    /*display: flex;
    justify-content: center;*/
}

.contentHolderGray {
    background-color: #dfdfdf;
    /*display: flex;
    justify-content: center;*/
}

.contentHolderTight {
    background-color: #FFF;
    border-radius: 10px;
    border-color: #565A5C;
    border-width: 0px;
    border-style: groove;
    padding: 10px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 5px;
    margin-bottom: 5px;
    /*z-index: 100000;*/
    box-shadow: 10px 10px 5px rgba(0,0,0,.1);
    /*max-height: 90vh*/
}

.contentHolderNoMargin {
    background-color: #FFF;
    border-radius: 10px;
    border-color: #565A5C;
    border-width: 0px;
    border-style: groove;
    padding: 40px;
    /*margin-left: 40px;
    margin-right: 40px;
    margin-top: 15px;
    margin-bottom: 15px;
        */
    /*z-index: 100000;*/
    box-shadow: 10px 10px 5px rgba(0,0,0,.1);
    max-height: 70vh
}

.contentHolderCriteria {
    position:relative;
    left:40px
}

.contentHolder_scorecard {
    background-color: #FFF;
    border-radius: 10px;
    border-color: #565A5C;
    border-width: 0px;
    border-style: groove;
    padding: 10px;
    margin: 10px;
    /*width:10%;*/
    /*z-index: 100000;*/
    box-shadow: 10px 10px 5px rgba(0,0,0,.1);
}



.contentHolderSkinny {
    background-color: #FFF;
    border-radius: 10px;
    border-color: #565A5C;
    border-width: 0px;
    border-style: groove;
    padding: 10px;
    margin: 10px;
    /*z-index: 100000;*/
    box-shadow: 10px 10px 5px rgba(0,0,0,.1);
}

.contentHolder_ScreenOrdering {
    background-color: #FAFAFA;
    border-radius: 10px;
    border-color: #565A5C;
    border-width: 0px;
    border-style: groove;
    padding: 40px;
    margin:25px;
    /*margin-left: 40px;
    margin-right: 40px;
    margin-top: 15px;
    margin-bottom: 15px;
        */
    /*z-index: 100000;*/
    box-shadow: 10px 10px 5px rgba(0,0,0,.1);
    max-height: 90vh
}

.dot {
    height: 5vw;
    width: 5vw;
    background-color: #b3b3b3;
    border-radius: 50%;
    display: inline-block;
    min-width: 50px;
    min-height: 50px;
}


.dotsmall {
    height: 49px;
    width: 49px;
    background-color: #FFF;
    border-radius: 50%;
    border-color: #8E908F;
    border-style: solid;
    border-width: 1px;
    display: inline-block;
    margin: 5px;
}

.dotsmall_hideborders {
    height: 49px;
    width: 49px;
    background-color: #b3b3b3;
    border-radius: 50%;
    border-color: #b3b3b3;
    border-style: solid;
    border-width: 1px;
    display: inline-block;
    margin: 5px;
}

.box {
    height: 25px;
    width: 25px;
    background-color: #FFF;
    display: inline-block;
}

.testingborders {
    border-color: darkred;
    border-width: 5px;
    border-style: groove;
}

.npdborders {
    border-color: #565a5c;
    border-width: 2px;
    border-style: solid;
}

.actionbarsimentry {
    border-radius: 5px;
    border-color: #F4F4F4;
    border-style: solid;
    border-width: 1px;
    background-color: #F4F4F4;
}

.addrowbuttonsim {
    margin: 10px;
    border-radius: 1px;
    background-color: #4E106F;
    border-color: #4E106F;
}

.formfieldtitle {
    /*font-size: px;*/
    font-weight: bold;
    color: #000000;
}

.formfieldtitle2 {
    font-size: 15px;
    /*font-size: .7rem;*/
    font-weight: bold;
    color: #000000;
}

.formfieldtitle3 {
    font-size: 15px;
    font-weight: bold;
    color: #000000;
}

.formfieldtitle4 {
    font-size: .9rem;
    font-weight: bold;
    color: #000000;
}

.formfieldNote {
    font-size: .9rem;
    font-weight: bold;
    color: #C41E3A;
}
.reportentry {
    background-color: #ffffff;
    width: 700px;
    font-family: Roboto, sans-serif;
    font-stretch: condensed;
    border-radius: 5px;
}


.contentbackground {
    background-color: #F4F4F4;
    height: 100%;
    width:100%;
}

.createlinks {
    font-size: 1rem;
    font-weight: bold;
    color: #4E106F;
    line-height: 6px;
    vertical-align: middle;
}

.reportlinks {
    font-size: 1rem;
    font-weight: bold;
    color: #565A5C;
    line-height: 6px;
    vertical-align: middle;
}

.reportlinks:hover {
    /*color: #FFF;
background-color: #0078BF;*/
}

.reportlinksdiv {
    height: 50px;
    vertical-align: middle;
    line-height: 12px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: #CCC;
    padding: 15px;
    display: inline-block;
    width: 100%
}

.reportlinksfavoritediv {
    height: 50px;
    vertical-align: middle;
    line-height: 12px;
    border-width: 1px 1px 1px 0px;
    border-style: solid;
    border-color: #CCC;
    padding: 15px;
    display: inline-block;
    width: 100%
}

.reportlinksclosediv {
    height: 25px;
    vertical-align: middle;
    line-height: 2.5px;
    border-width: 1px;
    border-style: solid;
    border-color: #CCC;
    padding: 15px;
    display: inline-block;
    width: 100%;
    color: #4E106F;
    cursor: pointer;
    font-weight: bold;
}

.reportlinksclosediv:hover > span {
    color: #FFF;
    text-decoration: none
}

    .reportlinksclosediv:hover {
        color: #FFF;
        background-color: #4E106F;
    }

.reportlinksdiv:hover > a {
    color: #FFF;
    text-decoration: none
    /*background-color: #0078BF;*/
}

.reportlinksdiv:hover {
    color: #FFF;
    background-color: #4E106F;
}

.reporttabsheader {
    font-size: 1.2rem;
    font-weight: normal;
    color: #565A5C;
}

.reporttabsheader:active {
    font-size: 1.2rem;
    font-weight: bold;
    color: #565A5C;
}


.tableheader {
    font-size: 1.2rem;
    font-weight: normal;
    color: #565A5C;
}

sidenav---sidenav-subnav---1EN61:hover {
    color: #FFF;
}

sidenav---navitem---9uL5T:hover {
    color: #FFF;
}

sidenav---navtext---1AE_f:hover {
    color: #FFF;
}

sidenav---sidenav-subnavitem---1cD47:hover {
    color: #FFF;
}

.MuiCheckbox-root {
    color: #000
}

.Mui-focused {
    color: #000
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #4E106F
}

.MuiBox-root-231{
    padding:0px
}



.highcharts-data-table table thead tr {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: .75rem;
    min-width: 50%;
    max-width: 50%;
    margin: 0px;
    margin-top: 10px;
    font-family: Roboto, sans-serif;
    font-size: 0.9rem;
    font-stretch: condensed;
}

.highcharts-table-caption {
    border-collapse: collapse;
    border-spacing: 0;
    background: white;
    width: 100%;
    margin-top: 10px;
    font-family: Roboto, sans-serif;
    font-size: 0.9rem;
    font-stretch: condensed;
}

.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
    border: 1px solid #ccc;
    font-size: 1rem;
    padding: 0px;
    text-align:center;
}

.highcharts-data-table tr:nth-child(even), .highcharts-data-table thead tr {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #eff;
}

.highcharts-data-table caption {
    border-bottom: none;
    font-size: 1.1rem;
    font-weight: bold;
}

.highcharts-caption {
    font-family: 'Roboto, sans-serif';
    fill: #4E106F;
    font-stretch: condensed;
}

.sidebar_div {
    /*background-color: #F4F4F4*/


}

.sidebar_div:hover {
    background-color: #F4F4F4
}

.sidebar_div_active {
    background-color: #bfbfbf;    
}

.simcomp_min {
    color: #fff;
    background-color: #EC7A08;
    font-family: 'Roboto, sans-serif';
    font-stretch: condensed;
}

.simcomp_max {
    color: #fff;
    background-color: #4E106F;
    font-family: 'Roboto, sans-serif';
    font-stretch: condensed;
}

.simcomp_normal {
    color: #565A5C;
    background-color: #fff;
    font-family: 'Roboto, sans-serif';
    font-stretch: condensed;
}

.simcomp_start {
    color: #fff;
    background-color: #8E908F;
    font-family: 'Roboto, sans-serif';
    font-stretch: condensed;
}

.mainbodydiv {
    width: 100%;
    min-height: 100%; 
    position: absolute; 
    left: 120px; 
    top: 210px; 
    background-color: #FFFFFF; 
    max-width: calc(100vw - 120px);
    border-left-color: #4E106F;
    border-left-width: thin;
    border-left: solid;
    
}

.successmessage{
    background-color:green;
    color:white
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index:1;
}
.dateinput{
    width: 50%
}

.homepagehoveractive {
    background-color: #000000
}

.homepagehoveractive:hover {
    background-color: #000000; /*#0078bf;*/
    border-radius: 50px 50px;
}

.homepagehoveractiveetl {
    background-color: #000000
}

.homepagehoveractiveetl:hover {
    background-color: #000000; /*#0078bf;*/
    border-radius: 10px 10px;
}

.homepagehoverinactive {
    background-color: #FFFFFF
}

.homepagehoverinactive:hover {
    background-color: #c4c5c6; /*#0078bf;*/
    border-radius: 10px 10px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/*Flash the cell on change in data*/
@-webkit-keyframes change_data_flash {
    from {
        background-color: #4e106f;
    }

    to {
        background-color: inherit;
    }
}

@-moz-keyframes change_data_flash {
    from {
        background-color: #4e106f;
    }

    to {
        background-color: inherit;
    }
}

@-o-keyframes change_data_flash {
    from {
        background-color: #4e106f;
    }

    to {
        background-color: inherit;
    }
}

@keyframes change_data_flash {
    from {
        background-color: #4e106f;
    }

    to {
        background-color: inherit;
    }
}

.change_data_flash {
    -webkit-animation: change_data_flash 1s 1; /* Safari 4+ */
    -moz-animation: change_data_flash 1s 1; /* Fx 5+ */
    -o-animation: change_data_flash 1s 1; /* Opera 12+ */
    animation: change_data_flash 1s 1; /* IE 10+ */
}

.change_data_flash_two {
    -webkit-animation: change_data_flash 1s 1; /* Safari 4+ */
    -moz-animation: change_data_flash 1s 1; /* Fx 5+ */
    -o-animation: change_data_flash 1s 1; /* Opera 12+ */
    animation: change_data_flash 1s 1; /* IE 10+ */
}
.h7 {
    font-family: Roboto, sans-serif;
    font-size: 1.25rem;
    font-weight: normal;
    color: #4e106f;
    text-align: center;
    font-stretch: condensed;
}

.h8 {
    font-family: Roboto, sans-serif;
    font-size: .875rem;
    font-weight: normal;
    color: #8E908F;
    font-stretch: condensed;
}

.h9 {
    font-family: Roboto, sans-serif;
    font-size: 1.125rem;
    font-weight: normal;
    color: #8E908F;
    font-stretch: condensed;
}

.pdtfieldtitle {
    font-size: .875rem;
    font-weight: normal;
    color: #565A5C;
}
.radiofieldtitle {
    font-size: .8rem;
    font-weight: normal;
    color: #4e106f;
}
.greenHeader {
    font-family: Roboto, sans-serif;
    font-size: 1.375rem;
    font-weight: bold;
    color: #4E106F;
    font-stretch: condensed;
}
.greenHeaderSmall {
    font-family: Roboto, sans-serif;
    font-size: 1.0rem;
    font-weight: bold;
    color: #4E106F;
    font-stretch: condensed;
}

.testForDatePicker {
    text-align: center;
    width: 100%;
    padding-left:15px;
    padding-right:15px;
}

.react-daterange-picker {
    display: inline-flex;
    position: relative;
}

    .react-daterange-picker,
    .react-daterange-picker *,
    .react-daterange-picker *:before,
    .react-daterange-picker *:after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

.react-daterange-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
}

.react-daterange-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: hsla(0,0%,80%); 
    border-radius: 4px;
}

.react-daterange-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
    height: 100%;
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;
}

.react-daterange-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
}

.react-daterange-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
}

.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.react-daterange-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
}

.react-daterange-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
}

.react-daterange-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
}

.react-daterange-picker__button:enabled {
    cursor: pointer;
}

.react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
    stroke: #4e106f;
}

.react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
    stroke: #6d6d6d;
}

.react-daterange-picker__button svg {
    display: inherit;
}

.react-daterange-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
}

.react-daterange-picker__calendar--closed {
    display: none;
}

.react-daterange-picker__calendar .react-calendar {
    border-width: thin;
}

.SectionBox {
    background-color: #FFF;
    border-radius: 10px;
    border-color: #565A5C;
    border-width: 0px;
    border-style: groove;
    padding: 20px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;    
    box-shadow: 10px 10px 5px rgba(0,0,0,.1);
    width:max-content;
}

.SectionBoxSelected {
    background-color: #FFF;
    border-radius: 10px;
    border-color: #4e106f;
    border-width: 2px;
    border-style: groove;
    padding: 20px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 10px 10px 5px rgba(0,0,0,.1);
    width: max-content;
}

.simOutputGraphs {
    min-width: 800px;
    max-width: 1400px;
    width: calc(85vw - 150px);
    height: 100%;
    overflow-y: auto;
    max-height: 1000px;
}


.pdtOutputGraphs {
    min-width: 800px;
    max-width: 1400px;
    width: 85vw;
    height: 100%;
    overflow-y: auto;
    max-height: 1200px;
}

.outputStyle {
    width: 100%; 
    position: absolute; 
    left: 120px; 
    top: 60px; 
    background-color: #F4F4F4; 
    max-width: calc(100vw - 120px); 
    padding: 20px; 
    min-width: 1200px;
}

.homepageiconbox{
    height: 300px;
    min-width: 300px;
    max-width: 600px;
    margin: auto;
    text-align: center;
}

.homepageiconboxnew {
    height: 75px;
    min-width: 300px;
    max-width: 600px;
    margin: auto;
    text-align: center;
    background-color: transparent
}

.buttonunfilled {
    color: #4e106f;
    background-color: #FFFFFF;
    border-color: #4e106f;
    border-width: 2px;
    border-radius: 5px 5px;
    margin-right: 10px;
    margin-left: 10px;
    min-width: 50px;
    font-weight: bold;
}


.buttonfilled {
    color: #FFFFFF;
    background-color: #4e106f;
    border-width: 2px;
    border-radius: 5px 5px;
    margin-right: 10px;
    margin-left: 10px;
    min-width: 50px;
    font-weight: bold;
}

.rct-node-icon {
    display: none;

}


.rct-icon-check {
    color: rgb(78, 16, 111);
}


.rct-node-icon {
    color: rgb(78, 16, 111);
}
.react-checkbox-tree {
    display: flex;
    flex-direction: row-reverse;
    font-size: 8px;
}


.rct-bare-label {
    cursor: default;
}

.react-checkbox-tree ol ol {
    font-size: 12px;
    color: rgb(78, 16, 111);
}

.rct-title {
    padding: 0 5px;
    font-size: 14px;
}

.react-checkbox-tree button {
    line-height: normal;
    color: rgb(78, 16, 111);
    
    
   
   
}



.modal-open .modal {
    
    overflow-y: scroll;
    padding-right: 0 !important;
}

.modal-scrollbar {
    margin-right: 15px;
}

